exports.components = {
  "component---src-components-news-page-newsdetailed-jsx": () => import("./../../../src/components/news page/Newsdetailed.jsx" /* webpackChunkName: "component---src-components-news-page-newsdetailed-jsx" */),
  "component---src-components-research-compo-projects-projectdetail-jsx": () => import("./../../../src/components/Research Compo/projects/Projectdetail.jsx" /* webpackChunkName: "component---src-components-research-compo-projects-projectdetail-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administration-js": () => import("./../../../src/pages/Administration.js" /* webpackChunkName: "component---src-pages-administration-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/Alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/Books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-certificates-js": () => import("./../../../src/pages/Certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/ComingSoon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-events-awards-jsx": () => import("./../../../src/pages/events/Awards.jsx" /* webpackChunkName: "component---src-pages-events-awards-jsx" */),
  "component---src-pages-events-conclave-jsx": () => import("./../../../src/pages/events/Conclave.jsx" /* webpackChunkName: "component---src-pages-events-conclave-jsx" */),
  "component---src-pages-events-cyber-security-seminar-jsx": () => import("./../../../src/pages/events/CyberSecuritySeminar.jsx" /* webpackChunkName: "component---src-pages-events-cyber-security-seminar-jsx" */),
  "component---src-pages-events-expo-2022-jsx": () => import("./../../../src/pages/events/Expo2022.jsx" /* webpackChunkName: "component---src-pages-events-expo-2022-jsx" */),
  "component---src-pages-events-expo-2023-jsx": () => import("./../../../src/pages/events/Expo2023.jsx" /* webpackChunkName: "component---src-pages-events-expo-2023-jsx" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/Events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-labstart-jsx": () => import("./../../../src/pages/events/Labstart.jsx" /* webpackChunkName: "component---src-pages-events-labstart-jsx" */),
  "component---src-pages-events-opulence-2023-jsx": () => import("./../../../src/pages/events/Opulence2023.jsx" /* webpackChunkName: "component---src-pages-events-opulence-2023-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/News.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-patents-js": () => import("./../../../src/pages/Patents.js" /* webpackChunkName: "component---src-pages-patents-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/Publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/Research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/Startups.js" /* webpackChunkName: "component---src-pages-startups-js" */)
}

